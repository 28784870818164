import {useIntl} from 'react-intl'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/leaderboard'
        icon='element-11'
        title='Leaderboard'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItemWithSub
        to='/core/pages'
        title='Core'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <SidebarMenuItemWithSub to='/core/pages/companies' title='Companies' hasBullet={true}>
          <SidebarMenuItem
            to='/core/pages/companies/create'
            title='Create company'
            hasBullet={true}
          />
          <SidebarMenuItem to='/core/pages/companies/list' title='Company list' hasBullet={true} />
          {/* <SidebarMenuItem
            to='/crafted/pages/profile/companySetup'
            title='Company setup'
            hasBullet={true}
          /> */}
          {/* <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          /> */}
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/core/pages/games' title='Games' hasBullet={true}>
          <SidebarMenuItem to='/core/pages/games/create' title='Create game' hasBullet={true} />
          <SidebarMenuItem to='/core/pages/games/list' title='Game List' hasBullet={true} />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/core/pages/tournaments' title='Tournaments' hasBullet={true}>
          <SidebarMenuItem
            to='/core/pages/tournaments/create'
            title='Create Tournament'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/core/pages/tournaments/list'
            title='Tournament list'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/core/pages/quests' title='Quests' hasBullet={true}>
          {/* <SidebarMenuItem
            to='/core/pages/quests/create'
            title='Create Tournament'
            hasBullet={true}
          /> */}
          <SidebarMenuItem
            to='/core/pages/quests/list'
            title='Quests list'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>

      <SidebarMenuItem
        to='/users/list'
        title='Users'
        fontIcon='bi-archive'
        icon='element-plus'
      ></SidebarMenuItem>
      <SidebarMenuItemWithSub
        to='/setup'
        title='Platform Setup'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <SidebarMenuItem to='/setup/company' title='Company Setup' hasBullet={true} />
        <SidebarMenuItem to='/setup/game' title='Game Setup' hasBullet={true} />
        <SidebarMenuItem to='/setup/system' title='System Parameters' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/streamingSetup'
        title='Streaming Setup'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <SidebarMenuItem to='/streamingSetup/tag' title='Tag Setup' hasBullet={true} />
        <SidebarMenuItem to='/streamingSetup/file' title='File Setup' hasBullet={true} />
        <SidebarMenuItem to='/streamingSetup/list' title='File List' hasBullet={true} />

      </SidebarMenuItemWithSub>

      {/* <SidebarMenuItem to='/builder' icon='switch' title='Layout Builder' fontIcon='bi-layers' />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='profile-circle'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='/error' title='Errors' fontIcon='bi-sticky' icon='cross-circle'>
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='element-7'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub>
       */}
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Pages</span>
        </div>
      </div> */}
      {/* <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='message-text-2'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      {/* <SidebarMenuItem
        to='/apps/user-management/users'
        icon='abstract-28'
        title='Company'
        fontIcon='bi-layers'
      /> */}
      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTIcon iconName='code' className='fs-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}

export {SidebarMenuMain}
